@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400; /* http://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff */
  src: local('Open Sans'), local('OpenSans'), url("../fonts/open/openSans.woff") format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600; /* http://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff */
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/open/openSansSemiBold.woff") format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
 font-weight: 700; /* http://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/open/openSansBold.woff") format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
 font-weight: 800; /* http://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hnhCUOGz7vYGh680lGh-uXM.woff */
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/open/openSansExtraBold.woff") format('woff');
}
